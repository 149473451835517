export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լեզու"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Այս դաշտը պարտադիր է"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Առնվազն նշված կլորակները՝ ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Առավելագույն նշված կլորակները՝ ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մուտքագրեք վավեր էլ. փոստ"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մուտքագրեք վավեր բջջային հեռախոս"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մուտքագրեք վավեր ամսաթիվ"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Տեղեկագիր սկսելու"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Համաձայնություններ"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր ստորագրությունը"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մաքրել"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնդրում ենք թողնել ձեր ստորագրությունը"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ես կարդացել եմ, հասկացել եմ և համաձայն եմ Ծառայությունների պայմաններին"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Այո, համաձայն եմ"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ոչ, համաձայն չեմ"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր գրանցումը"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրանոց"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գրանցում"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ելք"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գիշերներ"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրեր"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Այստեղ կտտացնելով դուք համաձայնում եք <br /> Ծառայությունների պայմանների հետ"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ծառայությունների պայմաններ"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շարունակել"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դիտել մանրամասները"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրերի տեղեկատվություն"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շարունակել"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բեռնված փաստաթղթեր"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրի մանրամասները"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Փաստաթղթերի սկաներ"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բեռնեք ձեր անձնագրի լուսանկարները կամ սկանեք անձնագիրը՝ ձեր ինքնությունը ստուգելու համար: Մենք չենք պահում այս տվյալները:"])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մտածեք տեղեկությունները գլխավոր հյուրի համար"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մտածեք տեղեկությունները ուղեկցող հյուրի համար"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ընտրեք երկիր"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնդրում ենք պատրաստել ձեր ID-ն և ժպիտը՝ ավտոմատ փաստաթղթի ստուգման և նույնականացման գործընթացին անցնելու համար։ Դա չի տևի ավելի քան 1 րոպե։"])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բեռնեք ճանապարհորդական փաստաթուղթ"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սքաներեք ճանապարհորդական փաստաթուղթ"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Պահպանել"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սկսեք ստուգման գործընթացը"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հատուկ խնդրանքներ"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնդրանք"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարում"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Եկելու ժամանակ"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ավելի վաղ ժամանումը հաստատման ենթակա է հյուրանոցի կողմից"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարեք առցանց հիմա"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարեք ժամանելու ժամանակ"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնդրում ենք ընտրել, թե ինչպես կցանկանաք վճարել ձեր վարձավճարը"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նշում. Հյուրանոցային աշխատակիցները կհաստատեն լրացուցիչ ծառայությունների մատչելիությունը: Այս պահին դուք չեք վճարում դրանց համար."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր մնալը"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շարունակել"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մասնակցություն"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ավելորդ ծառայություններ"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարված"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ընդհանուր"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " գիշեր"]), _normalize([_interpolate(_named("n")), " գիշերներ"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բարելավեք ձեր մնալը"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ավելացրեք հավելումներ ձեր մնալը լուսավորելու համար մինչև դուք հասնեք և հաճելիորեն կզարմացնեք"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Փոխադրման ծառայություն"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնդրում ենք տրամադրել ձեր օդանավակայանը, ժամանման ամսաթիվը և թռիչքի համար՝ որպեսզի մենք կազմակերպենք փոխադրում ձեզ համար"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Օդանավակայան"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրերի քանակ"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ժամանման ամսաթիվ"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Թռիչքի համար"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շնորհակալություն!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հարգելի"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր նախնական գրանցումը հաջողությամբ ավարտվել է: Նախքան ժամանումը դուք կստանաք մեր կողմից վերջնական գրանցման հաստատման նամակ և հրահանգներ: Շնորհակալություն և մենք սպասում ենք ձեզ Hoteza Demo Hotel-ում:"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրանոցի կապեր"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["կտտացրեք կպցնելու համար!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կոորդինատները պատճենվել են!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարումն անհաջող է"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր վճարման տվյալները սխալ են: Խնդրում ենք փորձել նորից կամ հետ գնալ և ընտրել վճարել ժամանման ժամանակ:"])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Փորձել նորից"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնդրում ենք լրացնել ձեր տվյալները՝ ձեր գրանցումը գտնելու համար"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ազգանուն"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գրանցման ID"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ժամանման ամսաթիվ"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Այս տվյալներով գրանցում չի գտնվել: Ստուգեք մուտքագրած տվյալները կամ կապվեք հյուրանոցի հետ։"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սկսել դուրս գալու գործընթացը"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ավարտել դուրս գալու գործընթացը"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դուրս գալ"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարել առցանց"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վճարել ռեցեպցիայում"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շնորհակալություն!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ոչինչ չի ստացվել!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դուք ունեք չօգտագործված ավանդ: Խնդրում ենք այցելել ռեցեպցիա՝ ստանալու մնացած ավանդի վերադարձը:"])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ընտրեք փաստաթուղթ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ընտրեք, թե որ փաստաթուղթ եք ցանկանում սկանավորել"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անձնագիր"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID քարտ"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բնակիչ քարտ"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Առջևի ID քարտ"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հետին ID քարտ"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սելֆի"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Փաստաթուղթը ճանաչվել է և դեմքը համընկնում է: Խնդրում ենք ստուգել ճշգրտությունը:"])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր փաստաթղթերը բեռնվում են..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր փաստաթղթերը ստուգվում են..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Խնամից հավասարեցրեք ձեր ID-ի հետևի մասը՝ <br> և լուսանկարեք այն"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ներկայումս սկանավորեք ID-ի առջևի կողմը"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հավասարեցրեք ձեր անձնագիրը՝ <br> և լուսանկարեք այն"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դիմեք ձեր դեմքը շրջանակի մեջ"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ստուգեք ձեր լուսանկարն"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ստուգեք ձեր լուսանկարն: Համոզվեք, որ տառերը <br> հստակ են և լուսավորությունը լավ է"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լուսանկարը ստուգվում է..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դեմքը չի համընկնում"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Երկրի նկարիչը չի հայտնաբերվել"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նորից լուսանկարեք"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լուսանկարեք"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բոլորը ավարտված են!"])}
    },
    "feedback": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Մեկնաբանություն"])},
      "mostLikeForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ինչը ձեզ ավելի շատ դուր եկավ ձեր մնալում:"])},
        "services": {
          "bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սենյակի հարմարավետություն"])},
          "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Անվճար ֆիլմեր"])},
          "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սննդի որակը"])},
          "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Բջջային բանալին"])},
          "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրանոցային անձնակազմ"])}
        }
      },
      "wouldRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Կփորձեիք մեր հյուրանոցը այլ մարդկանց?"])},
      "whatImpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ինչպես ընդհանուր տպավորություն մնալուց:"])},
      "foodQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Սննդի որակը"])},
      "staffFriendliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հյուրանոցային անձնակազմի բարություն"])},
      "describeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Նկարագրեք ձեր փորձը՝ մեր հյուրանոցում մնալու մասին ձեր բառերով"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր մեկնաբանությունը"])},
      "ratings": {
        "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հիանալի"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Լավ"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Միջին"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Վատ"])}
      }
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հարգելի Հյուր"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շարունակել"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " մեծահասակ"]), _normalize([_interpolate(_named("n")), " մեծահասակներ"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " երեխա"]), _normalize([_interpolate(_named("n")), " երեխաներ"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ընտրեք ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Գտեք իմ գրանցումը"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ուղարկել"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Այո"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ոչ"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ինչ-որ բան սխալ գնաց։ <br>Խնդրում ենք փորձեք նորից"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Փակել"])}
  }
}